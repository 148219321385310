export const HowItWorksSection = () => (
    <div className="py-16 px-6 text-center ">
      <h2 className="text-3xl font-bold mb-6 text-[#263257] font-nunito">¿Cómo funciona?</h2>
      <p className="max-w-2xl mx-auto text-[#8A96BC] mb-8 font-inter">El proceso es simple y rápido. En menos de 5 minutos puedes empezar a recibir turnos y pagos automáticos.</p>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <h3 className="font-semibold text-xl font-nunito text-[#263257]">1. Registrate</h3>
          <p className=" font-inter text-[#8A96BC]">Crea tu cuenta y configura tu perfil.</p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <h3 className="font-semibold text-xl font-nunito text-[#263257]">2. Configura tu agenda</h3>
          <p className="font-inter text-[#8A96BC]">Establece tu disponibilidad y precios.</p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <h3 className="font-semibold text-xl font-nunito text-[#263257]">3. Empieza a recibir turnos</h3>
          <p className="font-inter text-[#8A96BC]">Los pacientes reservan y pagan en línea.</p>
        </div>
      </div>
    </div>
  );