import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HeroSection } from './components/HeroSection';
import { BenefitsSection } from './components/BenefitsSection';
import { HowItWorksSection } from './components/HowItWorksSection';
import { FAQSection } from './components/FAQSection';
import { WaitlistForm } from './components/WaitlistForm';
import { Footer } from './components/Footer';
import {Instructions} from './components/Instructions';



const App = () => {
  return (
    <Router>
    <div className="min-h-screen">
      <Routes>
        <Route path="/" element={
          <>
            <HeroSection />
            <BenefitsSection />
            <HowItWorksSection />
            <FAQSection />
            <WaitlistForm />
            <Footer />
          </>
        } />
        <Route path="/instrucciones" element={<Instructions />} />
      </Routes>
    </div>
  </Router>
  );
};

export default App;