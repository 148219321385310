import { FaInstagram, FaWhatsapp } from "react-icons/fa";

export const Footer = () => (
  <div className="py-6 bg-gray-800 text-white text-center flex flex-col items-center">
    <p className="text-sm font-inter">&copy; 2025 AgendaTuSalud. Todos los derechos reservados.</p>
    <p className="text-sm mt-2 font-inter">
      AgendaTuSalud es un producto desarrollado por{" "}
      <a className="font-bold hover:text-[#B28CFF]" target="_blank" rel="noopener noreferrer" href="https://www.mburu.tech">
        MburuTech
      </a>
    </p>
    
    {/* Íconos de redes sociales */}
    <div className="flex space-x-4 mt-4">
      <a
        href="https://www.instagram.com/agendatusaludapp/"
        target="_blank"
        rel="noopener noreferrer"
        className="text-white hover:text-[#B28CFF] transition-all"
      >
        <FaInstagram size={24} />
      </a>
      <a
        href="https://wa.me/543794222281?text=%C2%A1Hola!%20Quiero%20saber%20m%C3%A1s%20sobre%20AgendaTuSalud"
        target="_blank"
        rel="noopener noreferrer"
        className="text-white hover:text-[#B28CFF] transition-all"
      >
        <FaWhatsapp size={24} />
      </a>
    </div>
  </div>
);
