export const BenefitsSection = () => (
  <div className="py-16 px-6 text-center">
    <h2 className="text-3xl font-bold font-nunito mb-6 text-[#263257]">¿Por qué usar AgendaTuSalud?</h2>
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
      <div className="bg-white p-6 rounded-lg shadow-md cursor-pointer transform transition duration-300 hover:scale-105 hover:shadow-[0_4px_15px_#B28CFF]">
        <h3 className="font-semibold text-xl text-[#263257] font-nunito">Turnos y pagos automáticos</h3>
        <p className="text-[#8A96BC] font-inter">Olvídate de coordinar turnos por WhatsApp.</p>
      </div>

      <div className="bg-white p-6 rounded-lg shadow-md cursor-pointer transform transition duration-300 hover:scale-105 hover:shadow-[0_4px_15px_#B28CFF]">
        <h3 className="font-semibold text-xl text-[#263257] font-nunito">Sin costos fijos, sin suscripciones</h3>
        <p className="text-[#8A96BC] font-inter">Crea tu perfil gratis y cada turno se paga solo
        </p>
      </div>
      <div className="bg-white p-6 rounded-lg shadow-md cursor-pointer transform transition duration-300 hover:scale-105 hover:shadow-[0_4px_15px_#B28CFF]">
        <h3 className="font-semibold text-xl text-[#263257] font-nunito">Optimiza tu tiempo</h3>
        <p className="text-[#8A96BC] font-inter">Reduce cancelaciones y evita perder dinero.</p>
      </div>
    </div>
  </div>
);
