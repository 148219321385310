import { useState } from 'react';

export const WaitlistForm = () => {
  const [formData, setFormData] = useState({ name: '', specialty: '', email: '' });
  const [status, setStatus] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus(null);

    try {
      const response = await fetch("https://formspree.io/f/xyzyajqe", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setFormData({ name: '', specialty: '', email: '' });
        setStatus("¡Te has unido a la lista de espera con éxito!");
      } else {
        setStatus("Hubo un problema al enviarlo. Intenta nuevamente.");
      }
    } catch (error) {
      setStatus("Error al enviar el formulario. Verifica tu conexión.");
      console.error("Error al enviar la solicitud:", error);
    }
  };

  return (
    <div className="py-16 px-6 text-center text-white">
      <h2 className="text-3xl font-bold mb-6 text-[#263257] font-nunito">Unite a nuestra lista de espera</h2>
      <p className="text-lg mb-6 text-[#263257] font-inter">Accede antes que nadie y asegura tu agenda sin costos ocultos.</p>
      {status && <p className="text-center text-green-600">{status}</p>} 
      <form onSubmit={handleSubmit} className="space-y-4 max-w-md mx-auto">
        <input 
          type="text" 
          name="name" 
          placeholder="Ingresa tu nombre" 
          className="w-full p-3 border border-gray-300 rounded-md text-black font-inter" 
          required 
          value={formData.name}
          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
        />
        <input 
          type="text" 
          name="specialty" 
          placeholder="Ingresa tu especialidad" 
          className="w-full p-3 border border-gray-300 rounded-md text-black font-inter" 
          required 
          value={formData.specialty}
          onChange={(e) => setFormData({ ...formData, specialty: e.target.value })}
        />
        <input 
          type="email" 
          name="email" 
          placeholder="Ingresa tu email" 
          className="w-full p-3 border border-gray-300 rounded-md text-black font-inter" 
          required 
          value={formData.email}
          onChange={(e) => setFormData({ ...formData, email: e.target.value })}
        />
        <button type="submit" className="w-full bg-[#B28CFF] hover:bg-[#9B72F2] text-white py-2 px-4 rounded-md transition font-inter">
          Unirme a la lista
        </button>
      </form>
    </div>
  );
};
