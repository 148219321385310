import { Link } from 'react-router-dom';

export const FAQSection = () => (
    <div className="py-16 px-6 text-center">
      <h2 className="text-3xl font-bold mb-6 text-[#263257] font-nunito">Preguntas Frecuentes</h2>
      <div className="max-w-2xl mx-auto text-left">
        <div className="bg-white p-6 rounded-lg shadow-lg mb-4">
          <h3 className="font-semibold font-nunito text-[#263257]">¿Cuánto cuesta usar AgendaTuSalud?</h3>
          <p className="font-inter text-[#8A96BC]">Crear tu perfil es gratis. Solo se descuenta el 1% + la tarifa de Mercado Pago (6,29% + IVA) en cada turno reservado.</p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-lg mb-4">
          <h3 className="font-semibold font-nunito text-[#263257]">¿Cómo recibo mi dinero?</h3>
          <p className=" font-inter text-[#8A96BC]"><strong>Al instante</strong> en tu cuenta de Mercado Pago.</p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-lg mb-4">
          <h3 className="font-semibold font-nunito text-[#263257]">¿Los pacientes pueden cancelar?</h3>
          <p className="font-inter text-[#8A96BC]">Sí, pero la consulta ya está paga, por lo que no pierdes dinero.</p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-lg mb-4">
          <h3 className="font-semibold font-nunito text-[#263257]">Sin trucos ni trampas</h3>
          <p className="font-inter text-[#8A96BC]">Lee las simples instrucciones de uso y el detalle de costos <Link to="/instrucciones" className="text-[#263257] underline">acá</Link>.</p>
        </div>
      </div>
    </div>
  );