import { useState } from 'react';
import { useNavigate } from "react-router-dom";

export const Instructions = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });
  const [status, setStatus] = useState(null); // Estado para mostrar mensajes

  const handleSubmit = async (e) => {
    e.preventDefault(); // Evita la recarga de la página
    setStatus(null); // Resetea el estado de la respuesta

    try {
      const response = await fetch("https://formspree.io/f/xnnnkqnk", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setFormData({ name: '', email: '', message: '' }); // Limpia el formulario
        setStatus("¡Tu consulta ha sido enviada con éxito!"); // Mensaje de éxito
      } else {
        setStatus("Hubo un problema al enviar tu consulta. Intenta nuevamente.");
      }
    } catch (error) {
      setStatus("Error al enviar la consulta. Verifica tu conexión.");
      console.error("Error al enviar el formulario:", error);
    }
  };

  return (
    <div className="min-h-screen bg-white p-8 text-center">
      <h2 className="text-3xl font-bold mb-6 text-[#263257] font-nunito">Instrucciones de Uso</h2>
      <div className="max-w-2xl mx-auto text-left bg-white p-6 rounded-lg shadow-lg">
        <p className="text-gray-700 mb-4 font-inter">
          Bienvenido a <strong>AgendaTuSalud</strong>. Sigue estos sencillos pasos para comenzar:
        </p>
        <ul className="list-disc list-inside text-gray-700 font-inter">
          <li>Regístrate y crea tu perfil de médico.</li>
          <li>Configura tu disponibilidad horaria.</li>
          <li>Comparte tu perfil con pacientes.</li>
          <li>Recibe pagos automáticamente por Mercado Pago.</li>
          <li>Gestiona tus turnos sin necesidad de intermediarios.</li>
        </ul>

        <div className="mt-8 p-6 bg-gray-100 rounded-lg">
  <h3 className="text-2xl font-semibold text-[#263257] text-center font-nunito">💰 ¿Cuánto cuesta usar AgendaTuSalud?</h3>
  <p className="text-gray-700 text-center mt-4 font-inter">
    En <strong>AgendaTuSalud</strong> creemos en la transparencia y en brindarte la mejor solución sin costos ocultos. 
    Nuestra plataforma es <strong>100% gratuita</strong> para registrarte y gestionar tu agenda. Solo cobramos una 
    pequeña comisión por cada turno reservado.
  </p>

  <h4 className="text-xl font-semibold text-[#263257] mt-6 font-nunito">📌 Desglose del costo por turno</h4>
  <ul className="list-disc list-inside text-gray-700 mt-2 font-inter">
    <li><strong>1% del valor del turno</strong> → Comisión por el uso de la plataforma.</li>
    <li><strong>6.29% + IVA</strong> → Comisión de Mercado Pago para procesar el pago y enviarlo automáticamente a tu cuenta.</li>
  </ul>

  <h4 className="text-xl font-semibold text-[#263257] mt-6 font-nunito">📊 Ejemplo práctico</h4>
  <p className="text-gray-700 mt-2 font-inter">
    Si el costo de tu consulta es de <strong>$10,000 ARS</strong>, el cobro se dividiría de la siguiente manera:
  </p>
  <div className="bg-white p-4 rounded-lg shadow-md mt-4 text-gray-700 font-inter">
    <ul className="list-none">
      <li>🔹 <strong>Tu consulta:</strong> $10,000 ARS</li>
      <li>🔸 <strong>Comisión AgendaTuSalud (1%):</strong> -$100 ARS</li>
      <li>🔸 <strong>Comisión Mercado Pago (6.29% + IVA):</strong> -$764.90 ARS</li>
      <li>✅ <strong>Dinero que recibes:</strong> <span className="text-green-600">$9,135.10 ARS</span></li>
    </ul>
  </div>

  <p className="text-gray-700 text-center mt-4 font-inter">
    El pago se procesa automáticamente y lo recibes al instante en tu cuenta de Mercado Pago. 
    Sin trámites, sin demoras y sin intermediarios. ¡Así de simple!
  </p>
</div>

        

        {/* Formulario de contacto */}
        <div className="mt-8 p-6 bg-gray-100 rounded-lg">
          <h3 className="text-2xl font-semibold text-[#263257] text-center font-nunito">¿Necesitas ayuda?</h3>
          <p className="text-gray-700 text-center mb-4 font-inter">Escríbenos y te responderemos a la brevedad.</p>
          
          {status && <p className="text-center text-green-600 font-inter">{status}</p>} {/* Muestra mensaje de éxito o error */}

          <form onSubmit={handleSubmit} className="space-y-4 max-w-md mx-auto">
            <input 
              type="text" 
              name="name" 
              placeholder="Tu nombre" 
              className="w-full p-3 border border-gray-300 rounded-md text-black font-inter" 
              required 
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            />
            <input 
              type="email" 
              name="email" 
              placeholder="Tu correo electrónico" 
              className="w-full p-3 border border-gray-300 rounded-md text-black font-inter" 
              required 
              value={formData.email}
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
            />
            <textarea 
              name="message" 
              placeholder="Escribe tu consulta aquí..." 
              className="w-full p-3 border border-gray-300 rounded-md text-black font-inter" 
              required 
              value={formData.message}
              onChange={(e) => setFormData({ ...formData, message: e.target.value })}
            ></textarea>
            <button type="submit" className="w-full bg-[#B28CFF] hover:bg-[#9B72F2] text-white py-2 px-4 rounded-md transition font-inter">
              Enviar consulta
            </button>
          </form>
        </div>

        <button 
          onClick={() => navigate(-1)} 
          className="mt-6 bg-[#B28CFF] hover:bg-[#9B72F2] text-white py-2 px-4 rounded-md transition ml-auto block font-inter"
>
          Volver Atrás
        </button>
      </div>
    </div>
  );
};
